@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&family=Noto+Sans:wght@300;400;500;600;700&display=swap');

body {
    margin: 0;
    font-family: 'Noto Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /*overflow: hidden;*/
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.m-0 {
    margin: 0 !important;
}

.p-0 {
    padding: 0 !important;
}

.ant-input-data-count {
    font-size: 0.75rem;
}

.ant-pagination .ant-pagination-next:not(.ant-pagination-disabled) button.ant-btn-primary span {
    color: white;
}

.ant-pagination .ant-pagination-next.ant-pagination-disabled {
    opacity: 0.5;
}

.with-circle-bg {
    background-image: url("../public/images/circle-bg.svg");
    background-repeat: no-repeat;
    background-position: center bottom;
}
.with-blue-logo-bg {
    background-image: url("../public/images/logo-blue-bg.svg");
    background-repeat: no-repeat;
    background-position: right top;
}

.fluid-content {
    padding: 5rem 3rem;
    max-width: 76.5rem;
    margin: 0 auto;
    width: 100%;
}

.wide-fluid-content {
    padding: 1.5rem;
    margin: 0 auto;
    width: 99%;
}

.underlined-link > span {
    text-decoration: underline;
}

.strong-btn {
    font-weight: 600;
}

.auth-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    min-height: calc(100vh - 5.5rem);
    align-self: center;
    background: radial-gradient(rgba(0,0,0,.99), rgba(0,0,0,0.4), rgba(0,0,0,0));
}

.auth-form {
    width: 21rem;
    margin: 0;
}

.ant-message .ant-message-notice-wrapper .ant-message-notice .ant-message-notice-content {
    border-radius: 2rem;
}

.full-height {
    min-height: calc(100vh - 5.5rem);
}