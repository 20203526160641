$headerHeight: 4rem;

.HomePageContent {
  padding: 1rem;
  overflow: hidden;
}

.HomePageWrapper,  {
  height: calc(100vh - $headerHeight);
}

.ProjectsMenu {
  overflow: auto;
}

.HomePageWrapper {
  overflow: hidden;
}

.create-sitemap {
  margin-right: 1rem;
  margin-left: 0.5rem;
}