.SpinWrapper {
  position: absolute;
  top: 10rem;
  width: 100%;
  padding: 2rem;
  text-align: center;
}

.ProjectShorDescription {
  margin-bottom: 0.5rem;
}

.pageScrollable {
  max-height: calc(100vh - 21rem);
  overflow: auto;
}