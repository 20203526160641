.trial-page {
  height: calc(100vh - 4rem);
  display: block;

  & > .ant-layout-content {
    height: 100%;

    & > .ant-layout {
      height: 100%;

      .ant-layout-sider-children > div {
        height: 100%;
      }
    }
  }

  .sider-menu {
    margin: 1rem 1rem 1rem 0;
    border-radius: 0.5rem;
    padding-top: 0.5rem;

    .ant-menu {
      border: 0;
      max-height: calc(100% - 2rem);
      overflow: auto;
    }
  }

  .project-content {
    margin: 1rem 0;

    .analyse-more {
      padding: 0.5rem 1.25rem;
      background: white;
      border-radius: 0.5rem;
      margin-bottom: 1rem;
    }
  }

  .ant-menu-item:not(.ant-menu-item-selected) {
    border: 0.0625rem solid transparent;
  }

  .ant-menu-item.ant-menu-item-selected {
    border: 0.0625rem solid;
  }
}

.site-name {
  text-align: center;
  opacity: 0.2;
  margin-bottom: 10vh;
  margin-top: -15vh;

  &.animated {
    animation-name: fly-to-top;
    animation-duration: 2s;
  }
}

@keyframes fly-to-top {
  from {
    opacity: 0.2;
    margin-left: 0;
    margin-top: -15vh;
  }
  to {
    opacity: 0;
    margin-left: -50vw;
    margin-top: -70vh;
  }
}