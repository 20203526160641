$gray-1: #414141;
$gray-2:#bfbdbd;
$yellow-1: #ffe58f;
$orange-1: #FF570A;
$green-1: #99ef99;

.formatted-text {
    white-space: pre-wrap;
}

.pagination {
    margin: 0.5rem 0;
}

.fix, .selected-fix {
    background-color: $yellow-1;
    line-height: 1.2;
    margin: 0.125rem 0;
    cursor: pointer;
    display: inline-block;
}

.fix.unhelpful {
    background-color: $gray-2
}

.fix.helpful {
    background-color: $green-1;
}

.selected-fix, .fix:hover {
    background-color: $orange-1;
}

.tooltip-title{
    font-size: 0.75rem;
    font-weight: 700;
}

.tooltip-body {
    font-size: 0.75rem;
    font-weight: 400;
}

.tooltip-actions{
    display: flex;
    justify-content: space-between;
    margin-top: 0.5rem;
    .action {
        display: flex;
        padding: 0.25rem;
        align-items: flex-start;
        gap: 0.125rem;
        border-radius: 5rem;
        background: $gray-1;
    }
}

.feedback-text{
    display: flex;
    justify-content: space-between;
    font-size: 0.5rem;
    margin-left: 0.3rem;
    margin-right: 0.5rem;
    margin-top: 0.5rem;
}

.ant-tooltip-inner{
    padding: 1rem;
    min-width: 14.5rem !important;
}