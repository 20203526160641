$block-border-radius: 0.75rem;

.PageDetails {
  padding: 0.25rem 0.5rem 1rem;
  background: white;
  border-radius: 0.375rem;
  margin-top: 0.5rem;
}

.formatted-text {
  white-space: pre-wrap;
}

.form-item {
  margin-bottom: 1.5rem;
}

.scrollable-content {
  max-height: calc(100vh - 20.375rem);
  overflow: auto;
  line-height: 1.2;
}

.scrollableTrialContent .ant-tabs-content-holder, .scrollableTrialContent .analysedPageDetails {
  max-height: calc(100vh - 14rem);
  overflow: auto;
}

.project-content .ant-card .ant-card-body {
  padding: 1rem 1.25rem;
}

.quality-analysis-details .page-title {
  background: white;
  padding: 1rem;
  display: block;
  border-radius: $block-border-radius $block-border-radius 0 0;
}

.analysis-result-wrapper {
  padding: 0 1rem 1rem;
  background: white;
  border-radius: 0 0 $block-border-radius $block-border-radius;
}

.quality-pagination {
  background: white;
  margin-top: 1rem;
  border-radius: 0.5rem;
  padding: 0 1rem;
}

.analysis-status-details {
  padding: 0 1rem 1rem;
  background: white;
  border-radius: 0 0 $block-border-radius $block-border-radius;
  overflow: auto;
  height: calc(100vh - 16rem);

  img {
    display: block;
    margin: 0 auto;
  }
}

.error-analyzing-header {
  background: white;
  padding: 0.25rem 1rem;
  border-radius: $block-border-radius;
  height: 1.75rem;
}