$green-1: #37C81F;
$green-2: #DEFFD8;
$red-1: #FF6F6F;
$red-2: #FFE8E8;
$gray-1: #4C4C4C;
$gray-2: #CBCBCB;

.ant-btn-icon-green {
    background-color: $green-1;
    margin-right: 0.5rem;
    border-color: $green-1;
    color: white;

    &.ant-btn:not(:disabled):hover {
        background-color: $green-2;
        color: $green-1;
        border-color: $green-1;
    }

    &[disabled] {
        background-color: $green-1;
        color: white;
        border-color: $green-1;
    }

    &.muted[disabled] {
        background-color: $green-2;
        color: $green-1;
        border-color: $green-1;
    }
}

.ant-btn-icon-red {
    background-color: $red-1;
    border-color: $red-1;
    color: white;

    &.ant-btn:not(:disabled):hover {
        background-color: $red-2;
        color: $red-1;
        border-color: $red-1;
    }

    &[disabled] {
        background-color: $red-1;
        color: white;
        border-color: $red-1;
    }

    &.muted[disabled] {
        background-color: $red-2;
        color: $red-1;
        border-color: $red-1;
    }
}

.navigation-light {
    .ant-menu-item-selected {
        .ant-btn-link {
            color: #0043E0;

            &:hover {
                color: $gray-1 !important;
            }
        }
    }

    .ant-btn-link {
        color: #191919;

        &:hover {
            color: $gray-1 !important;
        }
    }
}

.navigation-dark {        
    background-color: black;
    .ant-menu-item-selected {
        background-color: black !important;
        .ant-btn-link {
            color: #A2AFEC;

            &:hover {
                color: $gray-2;
            }
        }
    }

    .ant-btn-link {
        color: white;

        &:hover {
            color: $gray-2;
        }
    }
}