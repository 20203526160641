$headerHeight: 4rem;

.AppHeader {
  background: #fff;
  border-bottom: 0.0625rem solid #CBCBCB;
  height: $headerHeight;

  .AppLogoWrapper {
    height: $headerHeight;
    overflow: hidden;
  }

  .LogoLink {
    height: $headerHeight;
  }
}