.PageDetails {
  padding: 0.25rem 0.5rem 1rem;
  background: white;
  border-radius: 0.375rem;
  margin-top: 0.5rem;
}

.formatted-text {
  white-space: pre-wrap;
}

.form-item {
  margin-bottom: 1.5rem;
}

.quality-analyses {
  max-height: calc(100vh - 33rem);
  overflow: auto;
}

.scrollable-content-page-details {
  max-height: calc(100vh - 28.2rem);
  overflow: auto;
}

.analyses-container .ant-card-body {
  padding-top: 0.5rem;
  padding-bottom: 0rem; 
}